import { Image, Flex } from '@chakra-ui/react';


interface CarouselImageProps {
    filePath : string
    altText: string
    imageHeight: string
}

export const CarouselImage = ({filePath, altText, imageHeight}: CarouselImageProps) => {
    return (
        <Flex background={'linear-gradient(45deg, rgba(2,0,36,1) 0%, rgba(255,255,255,0) 48%, rgba(0,0,0,1) 100%);'}>
            <Image 
                objectFit={'cover'}
                width='100%'
                height={imageHeight}
                alt={altText}
                src={filePath}
                filter={'brightness(0.7)'}
                opacity={'90%'}
            />
        </Flex>
    )
}
