
import { useContext } from 'react'
import { siteSettingsContext } from '../../context/SiteSettingsContext'

import { SiteLogo } from './SiteLogo'
import { HStack, Text, Flex, Box, VStack, Stack } from "@chakra-ui/react"
import HeaderLink from './HeaderLink'
import HeaderLinkDivider from './HeaderLinkDivider'

interface SiteHeaderProps {
    contactHandler : () => void
}


export const SiteHeader = ({contactHandler} : SiteHeaderProps) => {
    const { siteHeader } = useContext(siteSettingsContext);

    return (
        <HStack 
            className='SiteHeader' 
            width={'100%'} 
            height={'120px'} 
            px='20px' 
            justifyContent={'space-between'} 
            // bg="rgb(255, 238, 186)"
            position={'absolute'}
            zIndex={1}
            color={'white'}
        >
            <Flex 
                className='LogoContainer' 
                h='90%' 
                w={['160px', null,  '200px']} 
                bg='white' 
                borderRadius={'10px'} 
                justifyContent={'center'} 
                boxShadow={'1px 2px 3px rgba(0,0,0,.5);'}
            >
                <SiteLogo logoFileName={siteHeader.logoFileName} />
            </Flex>
            
            <Flex fontSize={['30px', null, '35px', '65px']} h='100%' className="'SiteHeaderTitle" alignItems={'center'} display={['none', 'none', 'flex']}>
                <Text>{siteHeader.title}</Text>
            </Flex>

            <VStack>
                <Flex fontSize={['25px', null, '35px', '65px']} className="'SiteHeaderTitle" alignItems={'center'} display={['flex', null, 'none']}>
                    <Text pt='10px'>{siteHeader.title}</Text>
                </Flex>
                <HStack h={"100%"} className="'SiteNavigationLinks" alignItems={'flex-end'}>
                    {siteHeader.navigationLinks.map((x, i) => {
                        return ( <Flex alignItems={'center'}>
                                <HeaderLink label={x.label} url={x.url}/>
                                <HeaderLinkDivider/>
                            </Flex>
                        )
                    }
                    )}
                    <Box cursor={'pointer'} className='NavigationLinkContactUs' _hover={{color : 'orange'}} pr='8px'
                        onClick={() => contactHandler()}>
                        <Text fontSize={['12px', null, '20px']}>Contact Us</Text>
                    </Box>
                </HStack>
            </VStack>

        </HStack>
    )
}