import React from 'react';
import { Box, IconButton, useBreakpointValue} from '@chakra-ui/react';

// Here we have used react-icons package for the icons
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';

// And react-slick as our Carousel Lib
import Slider from 'react-slick';

import luxuryHouse from '../../media/house-image1.png'
import commConstruct1 from '../../media/comm-construct-1.jpg'
import constructSiteVideo from '../../media/construction-site.mp4'
import { CarouselImage } from './CarouselImage';
import { CarouselVideo } from './CarouselVideo';


interface CaptionCarouselProps {
  carouselSize : string
}

// Settings for the slider
const settings = {
  dots: true,
  arrows: false,
  fade: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const CaptionCarousel = ({carouselSize} : CaptionCarouselProps) => {
  // As we have used custom buttons, we need a reference variable to
  // change the state
  const [slider, setSlider] = React.useState<Slider | null>(null);

  // These are the breakpoints which changes the position of the
  // buttons as the screen size changes
  const top = useBreakpointValue({ base: '90%', md: '50%' });
  const side = useBreakpointValue({ base: '30%', md: '40px' });

  // This list contains all the data for carousels
  // This can be static or loaded from a server

  return (
    <Box
      className='CarouselBox1'
      position={'relative'}
      height={carouselSize}
      // height={'60vw'}
      width={'100%'}
      mb='30px'
    >
      {/* CSS files for react-slick */}
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      {/* Left Icon */}
      <IconButton
        aria-label="left-arrow"
        variant="ghost"
        position="absolute"
        left={side}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        color={'white'}
        _hover={{background : 'black'}}
        onClick={() => slider?.slickPrev()}>
        <BiLeftArrowAlt size="40px" />
      </IconButton>
      {/* Right Icon */}
      <IconButton
        aria-label="right-arrow"
        variant="ghost"
        position="absolute"
        right={side}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        color={'white'}
        _hover={{background : 'black'}}
        onClick={() => slider?.slickNext()}>
        <BiRightArrowAlt size="40px" />
      </IconButton>
      {/* Slider */}
      <Slider {...settings} ref={(slider) => setSlider(slider)} className='CarouselSlider'>
        <CarouselVideo videoHeight={carouselSize} filePath={constructSiteVideo} />
        <CarouselImage imageHeight={carouselSize} filePath={luxuryHouse} altText='Image of Beautiful Luxury Home'/>
        <CarouselImage imageHeight={carouselSize} filePath={commConstruct1} altText='Image of Commercial Construction Site'/>
      </Slider>
    </Box>
  );
}


export default CaptionCarousel