import { Box, Flex } from "@chakra-ui/react"

interface CarouselVideoProps {
    filePath : string
    videoHeight: string
}


export const CarouselVideo = ({filePath, videoHeight}: CarouselVideoProps) => {
    return (
        <Flex background={'linear-gradient(185deg, rgba(2,0,36,1) 0%, rgba(255,255,255,0) 48%, rgba(0,0,0,1) 100%);'}>
            <Box
                as="video" 
                loop
                playsInline
                webkit-playsinline
                webkit-playsInline
                autoPlay 
                muted
                disablePictureInPicture
                controlsList="nofullscreen"
                filter={'brightness(0.8)'}
                opacity={'80%'}
                style={{objectFit : 'cover', height: videoHeight, width:'100%'}}>
                <source src={filePath} type='video/mp4'
                />
                Your browser does not support the video tag.
            </Box>
        </Flex>
    )
}

