import './MainPage.css'
import { useState } from 'react'

import ContactCustomerData from '../models/ContactData/ContactCustomerData';
import { submit_to_email_sender } from '../utils';
import { useBreakpointValue, VStack, Flex } from '@chakra-ui/react'
import { Text, Box } from '@chakra-ui/react';
import CaptionCarousel from './SiteBody/Carousel';
import SiteBody from './SiteBody/SiteBody';

import { SiteHeader } from './SiteHeader/SiteHeader';
import SiteFooter from './SiteFooter/SiteFooter';
import ContactUs from './SiteModals/ContactUs';
import ThankYou from './SiteModals/ThankYou';
import { MobileQuotes } from './Mobile/MobileQuotes';

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..

const screenSizes = {
    base: 'Base',
    sm:   'Small',
    md:   'Medium',
    lg:   'Large',
    "2xl" : 'HDDisplay'
  }

const carouselSizes = {
    base: '300px',
    sm:   '300px',
    md:   '350px',
    lg:   '400px',
    "2xl" : '650px'
}

const scrollFadeOffsets = {
    base: '-300',
    sm:   '-300',
    md:   '0',
    lg:   '-200'
}

  const useDevView = process.env.REACT_APP_DEV_VIEW


const MainPage = () => {

    AOS.init();
    window.addEventListener('load', AOS.refresh)

    const [showContactUs, setContactUs] = useState(false)
    const [showThankYou, setThankyou] = useState(false)
    const screenSize = useBreakpointValue(screenSizes)
    const scrollFadeOffsetTemp = useBreakpointValue(scrollFadeOffsets, {fallback : '0px'})
    const scrollFadeOffset = (scrollFadeOffsetTemp) ? scrollFadeOffsetTemp : "0px"

    const handleSubmissionResponse = (event: any) => {
        console.log(event)
    }

    const toggleContactModal = () => {
        setContactUs(!showContactUs)
    }

    const toggleThankYouModal = () => {
        setThankyou(!showThankYou)
    }

    const handleContactSubmit = (info : ContactCustomerData) => {
        setContactUs(false)
        setThankyou(true)
        handleSubmissionResponse(true)
        submit_to_email_sender(info, handleSubmissionResponse)
    }

    let carouselSizeTemp : string | undefined = useBreakpointValue(carouselSizes)
    const carouselSize = (carouselSizeTemp) ? carouselSizeTemp : "650px"


    // TODO: Make Top Bar responsive mobile-friendly
    // TODO: Resize Carousel as page gets smaller, also add padding to bottom for small screens

    return (
        <VStack id='SiteStack' style={{fontFamily: "Times New Roman" }} gap={0} w='100%'>
            {/* HEADER */}
            <SiteHeader contactHandler={toggleContactModal}/>
            {/* <Box height={'120px'}></Box> */}
            {useDevView && <Text>{screenSize}</Text>}

            {/* BODY */}
            <CaptionCarousel
                carouselSize={carouselSize}
            />
            {/* Site Content Info */}
            <SiteBody fadeOffset={scrollFadeOffset} />

            <MobileQuotes handleContactUs={toggleContactModal} />

            {/* Footer */}
            <SiteFooter />

            <ContactUs 
                handleSubmit={handleContactSubmit}
                handleClose={toggleContactModal}
                isOpen={showContactUs} 
            />

            <ThankYou
                isOpen={showThankYou}
                handleClose={toggleThankYouModal}
            />
        
        </VStack>
    )
}

export default MainPage